import React from 'react';
import { useHistory } from 'react-router-dom';
import { authRedirect } from '../helpers';

export const AuthComponent: React.FC = () => {
    const history = useHistory();

    React.useEffect(() => {
        authRedirect(history);
    });

    return (
        <></>
    );
}
