import { CONFIG, APIS } from "./constants";

export const postConfig = (api: string, data: any) => {
    return fetch(CONFIG.URL_BASE_V1 + api, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then((res: any) => { 
        const body = res.json();
        return body;
    }).catch(err => {
        console.error(err);
    });
};

export const getConfig = (api: string) => {
    return fetch(CONFIG.URL_BASE_V1 + api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then((res: any) => { 
        const body = res.json();
        return body;
    }).catch(err => {
        console.error(err);
    });
};

export const putConfig = (api: string, data: any) => {
    return fetch(CONFIG.URL_BASE_V1 + api, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then((res: any) => { 
        const body = res.json();
        return body;
    }).catch(err => {
        console.error(err);
    });
};

export const deleteConfig = (api: string) => {
    return fetch(CONFIG.URL_BASE_V1 + api, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then((res: any) => { 
        const body = res.json();
        return body;
    }).catch(err => {
        console.error(err);
    });
};

export const truncateString = (str: string, num: number) => {
    if (str) {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    }
}

export const uploadImage = (image: any) => {
    const formData = new FormData();
    formData.append('file', image);

    return fetch(CONFIG.URL_BASE_V1 + APIS.URL_IMAGE, {
        method: 'POST',
        headers: {
            'file': 'multipart-form'
        },
        body: formData
    }).then(response => {
        return response.json();
    }).then(data => {
        return data.data;
    });
};

export const authRedirect = (history: any) => {
    const KEY = localStorage.getItem(CONFIG.KEY_LOCAL_STORAGE);
    switch (KEY) {
        case CONFIG.BLOG:
            history.push("/admin-blogs");
            break;
        case CONFIG.NOTIFICATION:
            history.push("/admin-notifications");
            break;
        default:
            history.push("/login");
            break;
    }
};
