import React from "react";
import {
    initialState,
    IState,
    reducer,
    Actions,
} from "./reducer";

interface IContextProps {
    state: IState;
    dispatch: React.Dispatch<Actions>;
};

export const NotiStore = React.createContext({} as IContextProps);

export function NotiStoreProvider(props: any) {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const value = { state, dispatch };

    return (
        <NotiStore.Provider
            value={value}
        >
            {props.children}
        </NotiStore.Provider>
    );
};
