import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React from 'react';
import { NotiStore } from '../../contexts/notification/store';
import { CONFIG } from '../../until/constants';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login(props: any) {
    const classes = useStyles();
    const [info, setInfo] = React.useState({email: '', password: ''});
    const { dispatch } = React.useContext(NotiStore);

    const login = () => {
        if (info.email === CONFIG.BLOG && info.password === CONFIG.PASS_ADMIN_BLOG ) {
            props.history.push('/admin-blogs');
            localStorage.setItem(CONFIG.KEY_LOCAL_STORAGE, CONFIG.BLOG);
            dispatch({type: 'SHOW', message: 'Login admin blog success!', status: 'success'});
            return;
        }

        if (info.email === CONFIG.NOTIFICATION && info.password === CONFIG.PASS_ADMIN_NOTIFICATION ) {
            props.history.push('/admin-notifications');
            dispatch({type: 'SHOW', message: 'Login admin notifications success!', status: 'success'});
            localStorage.setItem(CONFIG.KEY_LOCAL_STORAGE, CONFIG.NOTIFICATION);
            return;
        }
        
        dispatch({type: 'SHOW', message: 'Login error!', status: 'error'});
        return;
    };

    const handleInput = (event: any) => {
        setInfo({ ...info, [event.target.name]: event.target.value });
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography
                    component="h1"
                    variant="h5"
                >
                    Sign in
                    {process.env.PUBLIC_URL}
                </Typography>
                <div className={classes.form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => handleInput(e)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => handleInput(e)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={login}
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                </div>
            </div>
        </Container>
    );
}