import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { FooterLayout } from '../../components/footer-layout';
import { HeaderLayout } from '../../components/header-layout';
import { NotiStore } from '../../contexts/notification/store';
import { APIS } from '../../until/constants';
import { deleteConfig, truncateString, getConfig } from '../../until/helpers';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        padding: '10px 0',
    },
    footer: {
        padding: '10px 0',
    },
    title: {},
    addAction: {},
    titleColumn: {
        width: '25%',
    },
    contentColumn: {
        width: '50%',
    },
    actionsColumn: {
        textAlign: 'center',
        width: '25%',
    },
    iconItem: {
        marginLeft: 5,
    },
});

export default function ListNotification(props: any) {
    const classes = useStyles();
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [notifications, setNotifications] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { dispatch } = React.useContext(NotiStore);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deleteNotification = (notification: any) => {
        deleteConfig(`${APIS.NOTIFICATION_ADMIN}/${notification.notificationId}`).then(res => {
            if (res && res.code === 0) {
                dispatch({type: 'SHOW', message: "Delete notification success!", status: "success"});
                fetchListNotification();
                return;
            }
            dispatch({type: 'SHOW', message: res.message, status: 'error'});
            return;
        });
    }

    const Actions = (notification: any) => (
        <React.Fragment>
            <Fab
                color="secondary"
                aria-label="add"
                size="small"
                className={classes.iconItem}
                onClick={() => deleteNotification(notification)}
            >
                <DeleteIcon />
            </Fab>
        </React.Fragment>
    );

    const fetchListNotification = async () => {
        const res = await getConfig(`${APIS.NOTIFICATION_ADMIN}?page=${page}&size=${rowsPerPage}`);
        const notifications = res.data.notifications;
        setNotifications(notifications);
        setCount(res.data.total);
    };

    const redirectCreateNoti = () => {
        history.push("/admin-notification");
    };

    React.useEffect(() => {
        fetchListNotification();
    }, [page, rowsPerPage]);

    return (
        <React.Fragment>

            <HeaderLayout
                title="List notification"
                dispatchCreate={redirectCreateNoti}
            />
            
            <Paper className={classes.root}>

                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.titleColumn}>Title</TableCell>
                                <TableCell className={classes.contentColumn}>Content</TableCell>
                                <TableCell className={classes.actionsColumn}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notifications.map((notification: any, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <TableRow>
                                            <TableCell>
                                                {notification.title}
                                            </TableCell>
                                            <TableCell>
                                                {truncateString(notification.content, 100)}
                                            </TableCell>
                                            <TableCell className={classes.actionsColumn}>
                                                {Actions(notification)}
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

            </Paper>

            <FooterLayout />
        </React.Fragment>
    );
}
