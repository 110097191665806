import { Button, makeStyles, Paper, TextField, Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FooterLayout } from '../../components/footer-layout';
import { HeaderLayout } from '../../components/header-layout';
import { NotiStore } from '../../contexts/notification/store';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { uploadImage, putConfig, postConfig, deleteConfig } from '../../until/helpers';
import { APIS } from '../../until/constants';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: 10,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        padding: '10px 0',
    },
    item: {
        paddingBottom: 15,
    },
    title: {},
    addAction: {},
    footer: {
        padding: '10px 0',
    },
    button: {
        marginTop: theme.spacing(1),
    },
    imageContainer: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        height: 'auto',
    },
    imageItem: {
        width: 250,
        padding: 5,
        height: 'auto',
    },
    imageContainerItem: {
        position: 'relative',
    },
    buttonDeleteImage: {
        top: '50%',
        position: 'absolute',
        left: '50%',
        zIndex: 2,
        background: '#d7dada',
    },
}));

const GOALS = [
    {code: 'ielts', name: 'IELTS'},
    {code: 'toeic', name: 'TOIEC'},
    {code: 'toefl', name: 'TOEFL'},
    {code: 'communication', name: 'Communication'},
    {code: 'pte', name: 'PTE'},
    {code: 'accounting', name: 'Accounting'},
    {code: 'finalcial', name: 'Finalcial'},
    {code: 'banking', name: 'Banking'},
    {code: 'law', name: 'Law'},
    {code: 'medicine', name: 'Medicine'},
    {code: 'contruction', name: 'Contruction'},
    {code: 'school', name: 'School'},
    {code: 'marketing', name: 'Marketing'},
    {code: 'business', name: 'Business'},
    {code: 'education', name: 'Education'},
    {code: 'art', name: 'Art'},
    {code: 'economy', name: 'Aconomy'},
    {code: 'audit', name: 'Audit'},
    {code: 'technology', name: 'Technology'}
];

const LEVELS = [
    {code: 'beginner', name: 'Beginner'},
    {code: 'elementary', name: 'Elementary'},
    {code: 'intermediate', name: 'Intermediate'},
    {code: 'advance', name: 'Advance'},
];

const CreateEditAndViewPost: React.FC = (props: any) => {
    const classes = useStyles();
    const history = useHistory();
    const { match: { params }, location: {state}  } = props;
    const [postDetail, setPostDetail] = React.useState<any>(
        {
            title: '',
            content: '',
            images: [],
        }
    );
    const [goals, setGoals] = React.useState<any>([]);
    const [levels, setLevels] = React.useState<any>([]);
    const { dispatch } = React.useContext(NotiStore);

    const handleUpdatePost = () => {
        const { content, images, title } = postDetail;
        const goalsResult = goals.map((goal: any) => goal.code);
        const levelsResult = levels.map((level: any) => level.code);
        putConfig(`${APIS.BLOG_ADMIN}/${postDetail.blogId}`, { content, images, title, goals: goalsResult, levels: levelsResult }).then(res => {
            if (res && res.code === 0) {
                dispatch({type: 'SHOW', message: 'Update post success!', status: 'success'});
                history.push('/admin-blogs');
                return;
            }
            dispatch({type: 'SHOW', message: res.message, status: 'error'});
            return;
        });
    };

    const handleCreatePost = () => {
        const { content, images, title } = postDetail;
        const goalsResult = goals.map((goal: any) => goal.code);
        const levelsResult = levels.map((level: any) => level.code);
        postConfig(APIS.BLOG_ADMIN, { content, images, title, goals: goalsResult, levels: levelsResult }).then(res => {
            if (res && res.code === 0) {
                dispatch({type: 'SHOW', message: 'Create post success!', status: 'success'});
                history.push('/admin-blogs');
                return;
            }
            dispatch({type: 'SHOW', message: res.message, status: 'error'});
            return;
        });
    };

    const handleFileImage = (event: any) => {
        const files = Array.apply(null, event.target.files);
        const arrImageAsync: any = files.map(async (image: any) => {
            return await uploadImage(image);
        });

        Promise.all(arrImageAsync).then(images => {
            const postDetailImagesClone = [...postDetail.images];
            postDetailImagesClone.push(...images);
            handleChangePostDetail('images', postDetailImagesClone);
        })
    };

    const handleChangePostDetail = (name: string, value: any) => {
        const postDetailClone = postDetail;
        postDetailClone[name] = value;
        setPostDetail({ ...postDetail, [name]: value });
    };

    const removeImage = (index: number) => {
        const postDetailImagesClone = [...postDetail.images];
        postDetailImagesClone.splice(index, 1);
        handleChangePostDetail('images', postDetailImagesClone);
    };

    const deletePost = () => {
        deleteConfig(`${APIS.BLOG_ADMIN}/${postDetail.blogId}`).then(res => {
            if (res && res.code === 0) {
                dispatch({type: 'SHOW', message: "Delete post success!", status: "success"});
                history.push('/admin-blogs');
                return;
            }
            dispatch({type: 'SHOW', message: res.message, status: 'error'});
        });
    }

    React.useEffect(() => {
        const handleRedirect = () => {
            if (localStorage.getItem("auth") && params.mode === 'update') {
                setPostDetail(state);
                fillGoals(state.goals);
                fillLevels(state.levels);
                return;
            } else if (localStorage.getItem("auth") && params.mode === 'new') {
                return;
            }

            history.push('/login');
        }

        handleRedirect();
    }, []);

    const fillGoals = (goals: string[]) => {
        const result: any[] = [];
        const goalsMap = new Map();

        if (!goals) {
            return;
        }

        GOALS.forEach((goal) => {
            goalsMap.set(goal.code, goal);
        });

        goals.forEach((goal) => {
            const goalResult = goalsMap.get(goal);
            result.push(goalResult);
        });

        setGoals(result);
    };

    const fillLevels = (levels: string[]) => {

        if (!levels) {
            return;
        }

        const result: any[] = [];
        const levelsMap = new Map();

        LEVELS.forEach((level) => {
            levelsMap.set(level.code, level);
        });

        levels.forEach((level) => {
            const levelResult = levelsMap.get(level);
            result.push(levelResult);
        });

        setLevels(result);
    };

    return (
        <React.Fragment>
            <HeaderLayout
                mode={params.mode}
                title={params.mode === 'update' ? "Update post" : "Create post"}
                dispatchDelete={deletePost}
            />

            <Paper className={classes.root}>
                <div className={classes.item}>
                    <TextField
                        fullWidth
                        id="outlined-textarea"
                        size="small"
                        label="Title"
                        placeholder="Enter title"
                        variant="outlined"
                        value={postDetail.title}
                        onChange={(e) => handleChangePostDetail('title', e.target.value)}
                    />
                </div>
                <div className={classes.item}>
                    <Autocomplete
                        multiple
                        fullWidth
                        size="small"
                        options={GOALS}
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        value={goals}
                        onChange={(e, value) => setGoals(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Goals"
                                placeholder="Enter goals"
                            />
                        )}
                    />
                </div>
                <div className={classes.item}>
                    <Autocomplete
                        multiple
                        fullWidth
                        size="small"
                        options={LEVELS}
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        value={levels}
                        onChange={(e, value) => setLevels(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Levels"
                                placeholder="Enter level"
                            />
                        )}
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        size="small"
                        id="outlined-textarea"
                        label="Content"
                        placeholder="Enter content"
                        multiline
                        rows={25}
                        value={postDetail.content}
                        variant="outlined"
                        onChange={(e) => handleChangePostDetail('content', e.target.value)}
                    />
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        startIcon={<CloudUploadIcon />}
                    >
                        <input
                            accept="image/x-png,image/gif,image/jpeg,image/jpg"
                            type="file"
                            multiple
                            onChange={handleFileImage}
                        />
                    </Button>
                </div>
                <div className={classes.imageContainer}>
                    {postDetail && postDetail.images && postDetail.images.map((image: any, index: number) => {
                        return (
                            <React.Fragment key={index}>
                                <div className={classes.imageContainerItem}>
                                    <img
                                        className={classes.imageItem}
                                        src={image.url} alt={`image_${index}`}
                                    />
                                    <IconButton
                                        onClick={() => removeImage(index)}
                                        className={classes.buttonDeleteImage}
                                        aria-label="delete"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </React.Fragment>
                        )
                    })}
                </div>
            </Paper>

            <FooterLayout
                updatePost={handleUpdatePost}
                createPost={handleCreatePost}
                mode={params.mode}
            />
        </React.Fragment>
    )
}

export default CreateEditAndViewPost;