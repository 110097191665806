import { Fab, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        padding: '10px 0',
    },
});

export function HeaderLayout(props: any) {
    const classes = useStyles();
    const { mode, dispatchCreate, dispatchDelete } = props;

    const addNewRedirect = () => {
        dispatchCreate();
    };

    const deletePost = () => {
        dispatchDelete();
    }

    return (
        <div className={classes.root}>
            <div>
                <Typography
                    component="h1"
                    variant="h5"
                >
                    {props.title}
                </Typography>
            </div>
            <div>

                {
                    !mode &&
                    <Fab
                        color="secondary"
                        aria-label="add"
                        size="small"
                        onClick={addNewRedirect}
                    >
                        <AddIcon />
                    </Fab>
                }

                {
                    mode === 'update' && 
                    <Fab
                        color="secondary"
                        aria-label="add"
                        size="small"
                        onClick={deletePost}
                    >
                        <DeleteIcon />
                    </Fab>
                }
            </div>
        </div>
    )
}