import { Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CONFIG, APIS } from '../../until/constants';
import { NotiStore } from '../../contexts/notification/store';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { uploadImage, postConfig } from '../../until/helpers';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: 10,
        marginBottom: 10,
    },
    header: {
        padding: '10px 0'
    },
    item: {
        paddingBottom: 15,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    versionCodeItem: {
        padding: '0 10px',
        width: '100%',
    },
    versionCodeLabel: {
        paddingBottom: 5,
        fontSize: '1rem',
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 50,
    },
    betweenEl: {
        margin: '0 10px',
    },
    input: {
        display: 'none',
    },
    thumbnail: {
        width: 300,
    },
    imageContainerItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonItem: {
        marginLeft: 5,
    }
}));

const initVersionCodesMode = [
    {
        isActive: false,
        platform: "Android",
        versionCode: 0,
        operator: "",
    },
    {
        isActive: false,
        platform: "Ios",
        versionCode: 0,
        operator: "",
    }
];

const GOALS = [
    {code: 'ielts', name: 'IELTS'},
    {code: 'toeic', name: 'TOIEC'},
    {code: 'toefl', name: 'TOEFL'},
    {code: 'communication', name: 'Communication'},
    {code: 'pte', name: 'PTE'},
    {code: 'accounting', name: 'Accounting'},
    {code: 'finalcial', name: 'Finalcial'},
    {code: 'banking', name: 'Banking'},
    {code: 'law', name: 'Law'},
    {code: 'medicine', name: 'Medicine'},
    {code: 'contruction', name: 'Contruction'},
    {code: 'school', name: 'School'},
    {code: 'marketing', name: 'Marketing'},
    {code: 'business', name: 'Business'},
    {code: 'education', name: 'Education'},
    {code: 'art', name: 'Art'},
    {code: 'economy', name: 'Aconomy'},
    {code: 'audit', name: 'Audit'},
    {code: 'technology', name: 'Technology'}
];

const LEVELS = [
    {code: 'beginner', name: 'Beginner'},
    {code: 'elementary', name: 'Elementary'},
    {code: 'intermediate', name: 'Intermediate'},
    {code: 'advance', name: 'Advance'},
];

export default function Notification(props: any) {
    const classes = useStyles();
    const history = useHistory();
    const [action, setAction] = React.useState<string>("");
    const [author, setAuthor] = React.useState<string>("");
    const [url, setUrl] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [content, setContent] = React.useState<string>("");
    const [authorAvatar, setAuthorAvatar] = React.useState<string>("");
    const [image, setImage] = React.useState<any>();
    const [iosApp, setIosApp] = React.useState<string>("");
    const [androidApp, setAndroidApp] = React.useState<string>("");
    const [onlyVietnamUser, setOnlyVietnamUser] = React.useState<boolean>(false);
    const [usingJustInstall, setUsingJustInstall] = React.useState<boolean>(false);
    const [onlyProUser, setOnlyProUser] = React.useState<boolean>(false);
    const [goals, setGoals] = React.useState<any>([]);
    const [levels, setLevels] = React.useState<any>([]);

    const [versionCodesMode, setVersionCodesMode] = React.useState(initVersionCodesMode);
    const { dispatch } = React.useContext(NotiStore);

    const createNotification = () => {
        const data = {
            action,
            author,
            url,
            title,
            content,
            authorAvatar,
            image,
            iosApp,
            androidApp,
            onlyVietnamUser,
            usingJustInstall,
            onlyProUser,
            goals: goals.map((goal: any) => goal.code),
            levels: levels.map((level: any) => level.code),
            versionCodes: handleBindVersionCodes()
        };
        postConfig(APIS.NOTIFICATION_ADMIN, data).then(res => {
            if (res && res.code === 0) {
                dispatch({type: 'SHOW', message: 'Create notification success!', status: 'success'});
                resetData();
                backToNotifications();
                return;
            }
            dispatch({type: 'SHOW', message: res.message, status: "error"});
            return;
        });
    };

    const logout = () => {
        history.push('/login');
        localStorage.setItem(CONFIG.KEY_LOCAL_STORAGE, "");
    };

    const handleChangeVersionCodeItem = (index: number, e: any) => {
        let value;
        if (e.target.type === 'checkbox') {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }

        const versionCodesClone = [...versionCodesMode];
        const activeVersionCode = {...versionCodesClone[index], [e.target.name]: value};
        versionCodesClone[index] = activeVersionCode;
        setVersionCodesMode(versionCodesClone);
    };

    const handleBindVersionCodes = () => {
        const result: any = [];
        versionCodesMode.forEach(versionCode => {
            if (versionCode.isActive) {
                result.push({
                    platform: versionCode.platform,
                    versionCode: versionCode.versionCode,
                    operator: versionCode.operator,
                })
            }
        });
        return result;
    };

    const handleFileImage = (event: any) => {
        const file = event.target.files[0];
        const name = event.target.name;
        uploadImage(file).then(res => {
            if (res) {
                if (name === "authorAvatar") {
                    setAuthorAvatar(res.url);
                } else if (name === "image") {
                    setImage(res);
                }
                
            }
        });
    };

    const backToNotifications = () => {
        history.push('/admin-notifications');
    };

    const resetData = () => {
        setAction("");
        setAuthor("");
        setUrl("");
        setTitle("");
        setContent("");
        setAuthorAvatar("");
        setImage({})
        setIosApp("");
        setAndroidApp("");
        setOnlyVietnamUser(false);
        setUsingJustInstall(false);
        setOnlyProUser(false);
        setVersionCodesMode(initVersionCodesMode);
        setGoals([]);
        setLevels([]);
    };

    return (
        <React.Fragment>
            <Typography
                component="h1"
                variant="h5"
                className={classes.header}
            >
                Notification
            </Typography>

            <Paper className={classes.root}>
                <div className={classes.item}>
                    <TextField
                        size="small"
                        required
                        fullWidth
                        label="Author"
                        placeholder="Enter author"
                        variant="outlined"
                        value={author}
                        onChange={(e: any) => setAuthor(e.target.value)}
                    />
                </div>
                <div className={classes.item}>
                    <div>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="author-avatar-file"
                            type="file"
                            onChange={handleFileImage}
                            name="authorAvatar"
                        />
                        <label htmlFor="author-avatar-file">
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CloudUploadIcon />}
                                component="span"
                            >
                                Upload author avatar
                            </Button>
                        </label>
                    </div>
                    <div>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="upload-image-file"
                            type="file"
                            onChange={handleFileImage}
                            name="image"
                        />
                        <label htmlFor="upload-image-file">
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CloudUploadIcon />}
                                component="span"
                            >
                                Upload Image
                            </Button>
                        </label>
                    </div>
                </div>
                <div className={classes.item}>
                    {
                        authorAvatar &&
                        <div className={classes.imageContainerItem}>
                            <img
                                className={classes.thumbnail}
                                src={authorAvatar}
                                alt="author avatar"
                            />
                            <Typography
                                component="h4"
                            >
                                Author avatar
                            </Typography>
                            <div>
                                <IconButton
                                    onClick={() => setAuthorAvatar("")}
                                    aria-label="delete"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    }
                    {
                        image &&
                        image.url &&
                        <div className={classes.imageContainerItem}>
                            <img
                                className={classes.thumbnail}
                                src={image.url}
                                alt="thumbnail normal"
                            />
                            <Typography
                                component="h4"
                            >
                                Image
                            </Typography>
                            <div>
                                <IconButton
                                    onClick={() => setImage("")}
                                    aria-label="delete"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    }
                </div>
                <div className={classes.item}>
                    <TextField
                        size="small"
                        fullWidth
                        label="Url"
                        placeholder="Enter url"
                        variant="outlined"
                        value={url}
                        onChange={(e: any) => setUrl(e.target.value)}
                    />
                </div>
                <div className={classes.item}>
                    <Autocomplete
                        multiple
                        fullWidth
                        size="small"
                        options={GOALS}
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        value={goals}
                        onChange={(e, value) => setGoals(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Goals"
                                placeholder="Enter goals"
                            />
                        )}
                    />
                    <div className={classes.betweenEl}></div>
                    <Autocomplete
                        multiple
                        fullWidth
                        size="small"
                        options={LEVELS}
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        value={levels}
                        onChange={(e, value) => setLevels(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Levels"
                                placeholder="Enter level"
                            />
                        )}
                    />
                </div>
                <div className={classes.item}>
                    <TextField
                        size="small"
                        required
                        fullWidth
                        label="Title"
                        placeholder="Enter title"
                        variant="outlined"
                        value={title}
                        onChange={(e: any) => setTitle(e.target.value)}
                    />
                </div>
                <div className={classes.item}>
                    <TextField
                        size="small"
                        fullWidth
                        required
                        label="Content"
                        placeholder="Enter content"
                        multiline
                        rows={10}
                        variant="outlined"
                        value={content}
                        onChange={(e: any) => setContent(e.target.value)}
                    />
                </div>
                <div className={classes.item}>
                    <FormControl
                        size="small"
                        variant="outlined"
                        fullWidth
                        required
                    >
                        <InputLabel
                            id="action-field"
                        >
                            Action
                        </InputLabel>
                        <Select
                            labelId="action-field"
                            id="demo-simple-select-outlined"
                            value={action}
                            onChange={(e: any) => setAction(e.target.value)}
                            label="Action"
                        >
                            <MenuItem value="install_app">Install app</MenuItem>
                            <MenuItem value="open_web">Open web</MenuItem>
                            <MenuItem value="open_facebook">Open facebook</MenuItem>
                            <MenuItem value="update_app">Update app</MenuItem>
                            <MenuItem value="no_action">No action</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        size="small"
                        fullWidth
                        label="IOS app"
                        className={classes.betweenEl}
                        variant="outlined"
                        value={iosApp}
                        onChange={(e: any) => setIosApp(e.target.value)}
                    />
                    <TextField
                        size="small"
                        fullWidth
                        label="Android app"
                        variant="outlined"
                        value={androidApp}
                        onChange={(e: any) => setAndroidApp(e.target.value)}
                    />
                </div>
                <div className={classes.item}>
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={onlyVietnamUser}
                                    onChange={(e: any) => setOnlyVietnamUser(e.target.checked)}
                                />
                            }
                            label="Only Vietnam User"
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={usingJustInstall}
                                    onChange={(e: any) => setUsingJustInstall(e.target.checked)}
                                />
                            }
                            label="Using Just Install"
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={onlyProUser}
                                    onChange={(e: any) => setOnlyProUser(e.target.checked)}
                                />
                            }
                            label="Only pro user"
                        />
                    </div>
                </div>
                <div>
                    <div className={classes.versionCodeLabel}>Version Codes</div>

                    {versionCodesMode.map((versionCode, index) => {
                        return (
                            <div className={classes.item} key={index}>
                                <div className={classes.versionCodeItem}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        value={versionCode.platform}
                                        variant="outlined"
                                        disabled
                                    />    
                                </div>
                                <div className={classes.versionCodeItem}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        type="number"
                                        label="Version Code"
                                        variant="outlined"
                                        required={versionCode.isActive}
                                        disabled={!versionCode.isActive}
                                        value={versionCode.versionCode}
                                        onChange={(e) => handleChangeVersionCodeItem(index, e)}
                                        name="versionCode"
                                    />    
                                </div>
                                <div className={classes.versionCodeItem}>
                                    <FormControl
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        required={versionCode.isActive}
                                        disabled={!versionCode.isActive}
                                    >
                                        <InputLabel id="demo-simple-select-outlined-label">Operator</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={versionCode.operator}
                                            onChange={(e) => handleChangeVersionCodeItem(index, e)}
                                            label="Operator"
                                            name="operator"
                                        >
                                            <MenuItem value=">=">&gt;=</MenuItem>
                                            <MenuItem value="=">=</MenuItem>
                                            <MenuItem value="<=">=&lt;</MenuItem>
                                            <MenuItem value=">=">&gt;</MenuItem>
                                            <MenuItem value="<">&lt;</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className={classes.versionCodeItem}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                color="primary"
                                                name="isActive"
                                                checked={versionCode.isActive}
                                                onChange={(e) => handleChangeVersionCodeItem(index, e)}
                                            />
                                        }
                                        label="Active"
                                    />   
                                </div>
                            </div>
                        )
                    })}
                </div>
                
            </Paper>

            <div className={classes.footer}>
                <div>
                    <Button
                        color="secondary"
                        aria-label="add"
                        size="medium"
                        variant="contained"
                        onClick={logout}
                    >
                        Logout
                    </Button>
                </div>
                <div>
                    <Button
                        color="primary"
                        aria-label="add"
                        size="medium"
                        variant="contained"
                        onClick={backToNotifications}
                    >
                        Back to list
                    </Button>
                    <Button
                        color="primary"
                        aria-label="add"
                        size="medium"
                        className={classes.buttonItem}
                        variant="contained"
                        onClick={createNotification}
                    >
                        Create
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
}