import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        padding: '10px 0',
        display: 'flex',
        justifyContent: 'space-between',
    },
    buttonItem: {
        marginLeft: 5,
    }
});

export function FooterLayout(props: any) {
    const classes = useStyles();
    const history = useHistory();
    const {updatePost, createPost} = props;

    const logout = () => {
        history.push('/login');
        localStorage.clear();
    };

    const backToList = () => {
        history.push('/');
    };

    const emitUpdatePost = () => {
        updatePost();
    };

    const emitCreatePost = () => {
        createPost();
    };

    return (
        <div className={classes.root}>
            <div>
                <Button
                    color="secondary"
                    aria-label="add"
                    size="medium"
                    variant="contained"
                    onClick={logout}
                >
                    Logout
                </Button>
            </div>

            <div>
                {
                    (props.mode === 'update' || props.mode === 'new') && 
                    <Button
                        color="primary"
                        aria-label="add"
                        size="medium"
                        variant="contained"
                        onClick={backToList}
                    >
                        Back to list
                    </Button>
                }
                {
                    (props.mode === 'update') && 
                    <Button
                        color="primary"
                        aria-label="add"
                        size="medium"
                        variant="contained"
                        className={classes.buttonItem}
                        onClick={emitUpdatePost}
                    >
                        Update
                    </Button>
                }
                {
                    (props.mode === 'new') && 
                    <Button
                        color="primary"
                        aria-label="add"
                        size="medium"
                        variant="contained"
                        className={classes.buttonItem}
                        onClick={emitCreatePost}
                    >
                        Create
                    </Button>
                }
            </div>
        </div>
    )
}