import { Box, Container, CssBaseline } from '@material-ui/core';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import { AuthComponent } from './until/components/auth';
import Login from './features/login';
import Notification from './features/notification/create-notification';
import CreateEditAndViewPost from './features/posts/create-and-edit-post';
import ListPost from './features/posts/list';
import { NotiStoreProvider } from './contexts/notification/store';
import AlertComponent from './until/components/notification';
import ListNotification from './features/notification/list';

const App: React.FC = () => {
    return (
        <NotiStoreProvider>
            <React.Fragment>
                <CssBaseline />
                <Box mt={5}>
                    <Container fixed>
                        <BrowserRouter>
                            <Switch>
                                <Route exact path="/login" component={Login}/>
                                
                                <Route exact path="/" component={AuthComponent}/>
                                <Route exact path="/admin-notifications" component={ListNotification}/>
                                <Route exact path="/admin-notification" component={Notification}/>
                                <Route exact path="/admin-blogs" component={ListPost}/>
                                <Route path="/admin-blogs/:mode/:postId" component={CreateEditAndViewPost}/>
                            </Switch>
                        </BrowserRouter>    
                    </Container>
                </Box>
            </React.Fragment>
            <AlertComponent />
        </NotiStoreProvider>
    );
}

export default App;
