export const CONFIG = {
    URL_BASE_V1: 'https://vocabinnews.com/v1',
    BLOG: "blog",
    NOTIFICATION: "notification",
    KEY_LOCAL_STORAGE: "auth",
    PASS_ADMIN_BLOG: "admin",
    PASS_ADMIN_NOTIFICATION: "admin",
};

export const APIS = {
    BLOG_ADMIN: "/blogs/admin",
    URL_IMAGE: "/files/images",
    NOTIFICATION_ADMIN: "/homenotifications",
};
