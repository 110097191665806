import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { NotiStore } from '../../contexts/notification/store';

export default function AlertComponent() {
    const { state, dispatch } = React.useContext(NotiStore);

    const handleClose = () => dispatch({type: 'HIDE'});

    const {message, status} = state;

    return (
        <React.Fragment>
            <Snackbar
                open={state.isShow}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert
                    variant="filled"
                    onClose={handleClose}
                    severity={status}>
                    {message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}