export interface IState {
    isShow: boolean;
    message: string;
    status: any;
}

interface IShow {
    type: "SHOW";
    message: string;
    status: "success" | "error";
}

interface IHide {
    type: "HIDE";
}

export type Actions = IShow | IHide;

export const initialState: IState = {
    isShow: false,
    message: "",
    status: "success",
};

export const reducer = (state: IState, action: Actions) => {
    switch (action.type) {
        case "SHOW":
            return {
                ...state,
                isShow: true,
                message: action.message,
                status: action.status,
            };
        case "HIDE":
            return {
                ...state,
                isShow: false,
            };
    };
};
